(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("moment"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["moment", "vue"], factory);
	else if(typeof exports === 'object')
		exports["antd"] = factory(require("moment"), require("vue"));
	else
		root["antd"] = factory(root["moment"], root["Vue"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__14__) {
return 